import * as moment from "moment";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  NgForm,
} from "@angular/forms";
import { TimeScheduleService } from "../time-schedule.service";
import { Reservation, TimeSlot } from "../timeslot.model";
import { DateSchedule } from "../date-schedule.model";
import { BookingFormService } from "../booking-form.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DaySchedule, PriceInfo } from "../day-schedule.model";
import { CalendarTool } from "../../system/calendartool";
import { Schedule } from "../schedule.model";
import { BookableResourceService } from "../bookable-resource.service";
import { BookableResource } from "../bookable-resource";
import { Observable } from "rxjs/Observable";
import { combineLatest } from "rxjs/internal/observable/combineLatest";
import { map } from "rxjs/operators";

@Component({
  selector: "app-booking-form",
  templateUrl: "./booking-form.component.html",
  styleUrls: ["./booking-form.component.scss"],
})
export class BookingFormComponent implements OnInit {
  reservationForm: FormGroup;
  antall: Array<number> = [];

  durations: Array<PriceInfo> = [];
  awailAbleTimeslots = [];
  monthScheduleNew: Schedule;
  @Input()
  schedule: string;
  @Input()
  resourceUrl: string = "krsvag";
  private campaign: string = "";
  public resource$: Observable<BookableResource>;
  public resource: BookableResource;
  @Output()
  select: EventEmitter<TimeSlot> = new EventEmitter<TimeSlot>();
  @Output()
  bookingForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output("monthSchedule")
  monthScheduleEvent: EventEmitter<Schedule> = new EventEmitter<Schedule>();
  componentInit = true;
  constructor(
    private timeScheduleService: TimeScheduleService,
    private bookingFormService: BookingFormService,
    private bookingResourceService: BookableResourceService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.antall = Array(50)
      .fill(1)
      .map((x, y) => x + y);
    this.bookingFormService.clearAll();
  }
  public minDate = moment().toDate();

  ngOnInit() {
    var self = this;

    combineLatest(this.route.params, this.route.queryParams)
      .pipe(map((results) => ({ params: results[0], query: results[1] })))
      .subscribe((results) => {
        if (results.query.campaign) this.campaign = results.query.campaign;
        this.resourceUrl = results.params["refId"] || this.resourceUrl;
        this.resource$ = this.bookingResourceService.getResource(
          this.resourceUrl
        );
        this.resource$.subscribe((r) => {
          this.resource = r;
          this.bookingFormService.getReservationForm().subscribe((form) => {
            this.reservationForm = form;
            this.timeScheduleService
              .getSchedule(this.schedule, this.resource.id)
              .subscribe((schedule: Schedule) => {
                this.monthScheduleNew = schedule;
                this.monthScheduleEvent.emit(schedule);
                self.bookingForm.next(this.reservationForm);
                if (
                  self.reservationForm.controls.duration.valid &&
                  self.reservationForm.controls.timeSlot.valid
                ) {
                  setTimeout(() => {
                    self.selectionChanged(null);
                  });
                }
              });
          });
        });
      });
  }
  dateFilter(date: moment.Moment): boolean {
    var timeslots;
    return (
      this.monthScheduleNew &&
      (this.campaign == null ||
        this.campaign == "" ||
        this.monthScheduleNew.hasCampaign(date, this.campaign)) &&
      (timeslots = this.monthScheduleNew.getVacantTimeSlots(date)) != null &&
      timeslots.length > 0
    );
  }

  selectionChanged(event: Event, field: string = "") {
    if (!this.reservationForm) return;
    const date = moment(this.reservationForm.controls.day.value);
    if (this.reservationForm.controls.day.valid) {
      if (this.monthScheduleNew) {
        this.durations = this.monthScheduleNew.getPriceInfos(date);
        this.durations =
          this.campaign != ""
            ? this.durations.filter((x) => x.campaign == this.campaign)
            : this.durations.filter(
                (x) => x.campaign == null || x.campaign == ""
              );

        if (this.durations) this.reservationForm.controls.duration.enable();
      }
    }
    var validScheduleInfo =
      this.reservationForm.controls.day.valid &&
      this.reservationForm.controls.count.valid &&
      this.reservationForm.controls.duration.valid;
    if (
      (validScheduleInfo && this.monthScheduleNew) ||
      this.reservationForm.value.ignoreAvailabilityCheck
    ) {
      setTimeout(() => {
        this.awailAbleTimeslots = this.monthScheduleNew.getVacantTimeSlots(
          date,
          this.reservationForm.controls.count.value,
          this.reservationForm.controls.duration.value.duration,
          this.reservationForm.value.ignoreAvailabilityCheck
        );
      });
      this.reservationForm.controls.timeSlot.enable();
      if (
        (field == "count" || field == "duration") &&
        this.reservationForm.controls.timeSlot.value
      ) {
        var startTime = moment(
          this.reservationForm.controls.timeSlot.value.startTime
        );
        var timeSlotQuery = this.awailAbleTimeslots.filter((x) =>
          startTime.isSame(x.startTime)
        );
        if (timeSlotQuery.length > 0) {
          this.bookingFormService.patchReservationFrom({
            timeSlot: timeSlotQuery[0],
          });
          this.select.emit(timeSlotQuery[0]);
        }
      }
    }
  }

  test() {
    // debugger;
  }
  timeSlotSelected(event: Event) {
    this.select.emit(this.reservationForm.controls.timeSlot.value);
  }

  compareDurations(o1: any, o2: any) {
    return o1.duration == o2.duration;
  }
  compareTimeslots(o1: TimeSlot, o2: TimeSlot) {
    return (
      o1 &&
      o2 &&
      CalendarTool.formatDateTime(o1.start()) ==
        CalendarTool.formatDateTime(o2.start())
    );
  }
}
