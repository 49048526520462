import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TimeScheduleService } from '../time-schedule.service';
import { BookingFormService } from '../booking-form.service';
import { TimeSlot, Reservation } from '../timeslot.model';
import { DateSchedule } from '../date-schedule.model';
import * as moment from 'moment';
import {Location} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { Schedule } from '../schedule.model';
import { BookableResource } from '../bookable-resource';
import { BookableResourceService } from '../bookable-resource.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-confirmbooking',
  templateUrl: './confirmbooking.component.html',
  styleUrls: ['./confirmbooking.component.scss']
})
export class ConfirmbookingComponent implements OnInit {
  
  reservationForm: FormGroup;
  reservationForUserInfo: FormGroup;
  bookedOK: boolean= false;
  monthScheduleNew: Schedule;
  reservation: Reservation;
  reservationInfo: any;
  fullyBooked: boolean = false;
  error:boolean = false;
  message:string = "";
  runningCodeCheck: boolean;
  public resourceUrl:string ="krsvag";
  public resource$: Observable<BookableResource>;
  public resource:BookableResource;
  constructor(private timeScheduleService: TimeScheduleService,
    private bookingFormService:BookingFormService,
    private bookingResourceService: BookableResourceService,
    private location: Location,@Inject(DOCUMENT) private document: any,private router: Router,private aroute: ActivatedRoute) { }

  ngOnInit() {
    this.bookingFormService.getReservationForm().subscribe(form=>{
      this.reservationForm = form;
      this.reservationInfo = this.reservationForm.value;
    });
    this.reservation = this.bookingFormService.createReservation();
    
    this.reservationForUserInfo = this.bookingFormService
                                      .getReservationUserInfoForm();
    if(!this.reservationForm.valid){
      this.router.navigateByUrl(`/book/${this.resourceUrl}`);
    }
    this.aroute.params.subscribe(params => {
      this.resourceUrl = params['refId']||this.resourceUrl;
      this.resource$ = this.bookingResourceService.getResource(this.resourceUrl);
      this.resource$.subscribe(r=>{
        this.resource = r;
      });
    });
    this.timeScheduleService.getSchedule(this.reservation.dayScheduleType.toString(),this.reservation.referenceId)
    .subscribe((schedule:Schedule)=>{
      this.monthScheduleNew = schedule;
    });
  }
  reservationStart(){
    return  this.reservationInfo.startTime;
  }
  tilbake(){
    this.router.navigateByUrl(`/book/${this.resourceUrl}`);
  }
  

  sumPrice(){
    return this.bookingFormService.reservationPrice();
  }

  makeReservation(){
    this.runningCodeCheck = true;
    this.bookingFormService.makeReservation(this.monthScheduleNew)
    .subscribe((result)=> {
      this.error = false;
      this.bookedOK = true;
      this.reservation = result as Reservation;
      this.runningCodeCheck = false;
	  // Should not redirect in admin
    },
    (err)=> {
      this.error= true;
      this.bookedOK = false;
      if(err.status == 400 && err.error && err.error.full){
        this.fullyBooked = true;
        this.message = "Beklager, noen kom deg i forkjøpet, det er fult. Kunne ikke reservere tidspunktet. Prøv et annet tidspunktet.";
      }
      else if (err.status && err.status == 400) {
        this.message = "Feil mangler mobil nummer";
      }
      else if(err.status == 424){
        this.message = "Rusk i maskineriet. Prøv en gang til!";
      }
      else if(err.status >= 500){
        this.message = "Det er noe galt på serveren. Vi klarte dessverre ikke å gjennomføre reservasjonen din. Prøv igjen senere.";
      }
      else {
        this.message = "Noe gikk galt. Prøv igjen!";
      }
      this.runningCodeCheck = false;
    });
  }
}
