import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GbookingService } from '../gbooking.service';
import { GroupbookingForm } from '../groupbookingForm.model';
import { BookingFormService } from '../../time-scheduling/booking-form.service';
import { Reservation } from '../../time-scheduling/timeslot.model';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import { BookableResource } from '../../time-scheduling/bookable-resource';
import { BookableResourceService } from '../../time-scheduling/bookable-resource.service';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookingConfirmationComponent implements OnInit {
  // @Input() firstFormGroup: FormBuilder;
  public newBookingForm: GroupbookingForm = new GroupbookingForm();
  private reservationForm: FormGroup;
  reservation: Reservation;

  extrasForm: FormGroup;
  detailsFormGroup: FormGroup;
  messageForm: FormGroup;
  public resourceUrl:string ="krsvag";
  public resource:BookableResource;
  // bekredtelseFormGroup: FormGroup;
  constructor(private gbookingservice: GbookingService,
    private bookingFormService: BookingFormService,
    private bookingResourceService: BookableResourceService,
    private router: Router, private aroute: ActivatedRoute) {
    
    
    this.gbookingservice.extrasForm$.filter(x=>x!=null).subscribe(f=>{
      this.extrasForm = f;
      this.newBookingForm.bursdagspakkeForm = this.extrasForm.value;
    });
    this.messageForm = this.gbookingservice.getBeskjedFormGroup();
    this.reservation = this.bookingFormService.getStoredReservation();
    
    this.newBookingForm.allergipakkeForm = this.messageForm.value;
  }

  ngOnInit() {
    this.bookingFormService.getReservationForm().subscribe(form=>{
      this.reservationForm = form;
      this.newBookingForm.bookingForm = this.reservationForm.value;
    });
    this.aroute.params.subscribe(params => {
      this.resourceUrl = params['refId'] || this.resourceUrl;
      this.bookingResourceService.getResource(this.resourceUrl).subscribe(r=>{
        this.resource = r;
        this.detailsFormGroup = this.gbookingservice.getDetailsFormGroup(this.resource);
        this.newBookingForm.newWaiverForm = this.detailsFormGroup.value;
      });
    });
    // if(!this.reservationForm.valid || !this.extrasForm.valid){
    //   this.router.navigateByUrl("/booking")
    // }
    // this.gbookingservice.clearAll();
    // this.bookingFormService.clearAll();
    
  }

}


