import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BookingFormService } from "../../time-scheduling/booking-form.service";
import { Reservation } from "../../time-scheduling/timeslot.model";
import { GbookingService } from "../gbooking.service";
import "rxjs/add/operator/filter";
import { ActivatedRoute } from "@angular/router";
import { BookableResourceService } from "../../time-scheduling/bookable-resource.service";
import { BookableResource } from "../../time-scheduling/bookable-resource";

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  reservationForm: FormGroup;
  extrasForm: FormGroup;
  detailsForm: FormGroup;
  beskjedForm: FormGroup;
  resourceUrl: string = "krsvag";
  public resource: BookableResource;
  @Input() reservation: Reservation;
  constructor(
    private bookingFormService: BookingFormService,
    private gbookingservice: GbookingService,
    private bookingResourceService: BookableResourceService,
    private aroute: ActivatedRoute
  ) {
    this.aroute.params.subscribe((params) => {
      this.resourceUrl = params["refId"] || this.resourceUrl;
      this.bookingResourceService
        .getResource(this.resourceUrl)
        .subscribe((r) => {
          this.resource = r;
        });
    });
    this.bookingFormService.getReservationForm().subscribe((form) => {
      this.reservationForm = form;
    });
    //this.extrasForm = this.gbookingservice.getExtrasFrom();
    this.gbookingservice.extrasForm$
      .filter((x) => x != null)
      .subscribe((f) => {
        this.extrasForm = f;
      });
    this.detailsForm = this.gbookingservice.getDetailsFormGroup();
    this.beskjedForm = this.gbookingservice.getBeskjedFormGroup();
    this.reservation = this.bookingFormService.getStoredReservation();
  }

  ngOnInit() {}
  sumPrice() {
    if (!this.reservation) return 0;
    var unitPriceExtras = this.gbookingservice.totalPriceExtras(
      this.reservation.count
    );
    return this.bookingFormService.groupReservationPrice() + unitPriceExtras;
  }
  get minParticipants() {
    return Reservation.minParticipants;
  }
}
