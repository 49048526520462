import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SignatureLog } from '../../signature-log/signature-log';
import { SignatureLogService } from '../../signature-log/signature-log.service';
import * as moment from 'moment';
import { BookingFormService } from '../../time-scheduling/booking-form.service';
import { Schedule } from '../../time-scheduling/schedule.model';
import { GbookingService } from '../gbooking.service';
import { Guid } from '../../system/guid';
import { WaiverForm } from '../../waiver/waiverform.model';
import { Person } from '../../waiver/Person.model';
import { GroupReservation } from '../../time-scheduling/group/GroupReservation';


@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {

  @Input()
  confirmationRequest: SignatureLog;
  @Input()
  confirmationLog: SignatureLog;
  // @Input()
  groupReservationRequest:any;
  @Input()
  monthSchedule: Schedule;
  @Input()
  waiver_rules:Array<string> = [];
  @Output()
  onSignatureOk: EventEmitter<any> = new EventEmitter<any>();

  message: string = null;
  runningCodeCheck: boolean;
  isConfirmationOk: boolean;
  isConfirmationError:boolean;

  constructor(private signatureService: SignatureLogService,
    private bookingFormService: BookingFormService,
    private gbookingservice: GbookingService) { }

  ngOnInit() {

  }

  requestConfirmation() {
    this.runningCodeCheck = true;

    this.message = null;
    this.isConfirmationError = false;

    this.signatureService.requestConfirmation(this.confirmationRequest)
      .subscribe((result) => {
        this.message = "";
        this.runningCodeCheck = false;
      },(error)=>{
        if (error.status == 400) {
          this.message = "Feil mangler mobil nummer";
        }
        else if(error.status == 424){
          this.message = "Rusk i maskineriet. Prøv en gang til!";
        }
        else if(error.status >= 500){
          this.message = "Det er noe galt på serveren. Vi klarte dessverre ikke å gjennomføre reservasjonen din. Prøv igjen senere.";
        }
        else {
          this.message = "Noe gikk galt. Prøv igjen!";
        }
        this.runningCodeCheck = false;
        this.isConfirmationError = true;
      });
  }

  checkCode() {
    this.confirmationLog.occuredTime = moment().utc();
    this.submitCode();
  }
  //TODO move to service
  prepareGroupReservation(){
    var detailsForm = this.gbookingservice.getDetailsFormGroup();
    var beskjedForm = this.gbookingservice.getBeskjedFormGroup();

    var details: WaiverForm = detailsForm.value;
    var reservation = this.bookingFormService.getReservation(this.monthSchedule);
    details.children = details.children.map(x=> new Person(x.firstname,x.lastname,moment(x.birth,'DD.MM.YYYY')));

    var details: WaiverForm = detailsForm.value;
    reservation.mobile = details.mobile;

    var groupReservation: GroupReservation = {
      id: details.id,
      details: details,
      extras: this.gbookingservice.getExtras(),
      message: beskjedForm.controls.allergi.value,
      reservationId: reservation.id,
      reservation: reservation,
      reservationDate: reservation.startTime,
    };

    this.groupReservationRequest = {
      waiverRules: this.waiver_rules,
      reservation: reservation,
      groupReservation: groupReservation,
      signature: this.confirmationLog
    };
  }


  submitCode() {
    this.prepareGroupReservation();
    this.runningCodeCheck = true;
    this.bookingFormService.makeGroupeReservation(this.groupReservationRequest,this.monthSchedule)
    .subscribe((result)=>{
      this.isConfirmationOk = true;
      this.message = "";
      this.onSignatureOk.emit();
      this.runningCodeCheck = false;
    },(error)=>{
      if(error.status == 400){
        this.message = "Feil kode prøv igjen!";
      }
      else if(error.status == 424){
        this.message = "Rusk i maskineriet. Prøv en gang til!";
      }
      else if(error.status >= 500){
        this.message = "Det er noe galt på serveren. Vi klarte dessverre ikke å gjennomføre reservasjonen din. Prøv igjen senere.";
      }
      else {
        this.message = "Noe gikk galt. Prøv igjen!";
      }
      this.runningCodeCheck = false;
      this.isConfirmationError = true;
    });
  }

}
