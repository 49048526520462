import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NgForm,
  FormArray,
} from "@angular/forms";
import { MatStepperModule, MatStepper } from "@angular/material/stepper";
import { Person } from "../../waiver/Person.model";
import * as moment from "moment";
import { WaverFromService } from "../../waiver/waver-from.service";
import { SignatureLogService } from "../../signature-log/signature-log.service";
import { Observable } from "rxjs/Observable";
import { SignatureLog } from "../../signature-log/signature-log";
import { Guid } from "../../system/guid";
import { GroupBookingModule } from "../group-booking.module";
import { Router, ActivatedRoute } from "@angular/router";
import { GbookingService } from "../gbooking.service";
import { BookingFormService } from "../../time-scheduling/booking-form.service";
import { TimeSlot, Reservation } from "../../time-scheduling/timeslot.model";
import { WaiverForm } from "../../waiver/waiverform.model";
import { TimeScheduleService } from "../../time-scheduling/time-schedule.service";
import { DaySchedule } from "../../time-scheduling/day-schedule.model";
import { DateSchedule } from "../../time-scheduling/date-schedule.model";
import { Product } from "../catalog/product";
import { CatalogService } from "../catalog.service";
import { Pipe, PipeTransform } from "@angular/core";
import { GroupReservation } from "../../time-scheduling/group/GroupReservation";
import { Schedule } from "../../time-scheduling/schedule.model";
import { SignatureComponent } from "../signature/signature.component";

@Pipe({ name: "getValues" })
export class GetValuesPipe implements PipeTransform {
  transform(map: Map<any, any>): any {
    let ret = [];

    map.forEach((val, key) => {
      ret.push({
        key: key,
        val: val,
      });
    });
    return ret;
  }
}

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit {
  isLinear = false;
  reservation: Reservation;
  @ViewChild("stepper", { static: true })
  stepper: MatStepper;
  @ViewChild("signature", { static: false })
  signature: SignatureComponent;
  // firstFormGroup: FormGroup;
  extrasForm: FormGroup;
  detailsForm: FormGroup;
  beskjedForm: FormGroup;
  // bekreftelsesForm: FormGroup;
  reservationForm: FormGroup;
  selectedValue: string;
  selectedValuehoppetid: string;
  selectedValueAntall: string;
  selectedValueFood: number = 0;
  selectedValueExtra: number = 0;
  favoriteSeason: string;
  panelOpenState: boolean = false;
  showWaiverRulesbutton: boolean = false;
  hildeWaiverRulesbutton: boolean = false;
  showWaiverRules: boolean = true;
  public checkedkampanje = false;
  totalprice: number;
  public newbookingform: GroupBookingModule;
  runningCodeCheck: boolean = false;
  public confirmationRequest: SignatureLog;
  public confirmationLog: SignatureLog;
  public groupReservationRequest: any;
  public waiver_rules$: Observable<Array<string>>;
  public waiver_rules: Array<string> = [];
  public confirmSmSCode = false;
  public message: string = "";
  public checked = false;
  public checkedforesatt = false;
  public indeterminate = false;
  public isConfirmationOk: boolean = false;
  monthSchedule: Schedule;
  public catalog$: Observable<Array<Product>>;
  public catalog: Array<Product> = new Array<Product>();
  public resourceUrl: string = "krsvag";
  constructor(
    private aroute: ActivatedRoute,
    private rou: Router,
    private gfb: FormBuilder,
    private service: WaverFromService,
    private gbookingservice: GbookingService,
    private signatureService: SignatureLogService,
    private timeScheduleService: TimeScheduleService,
    private bookingFormService: BookingFormService,
    private catalogService: CatalogService
  ) {
    this.gbookingservice.clearAll();

    this.detailsForm = this.gbookingservice.getDetailsFormGroup();
    // this.bekreftelsesForm = this.gbookingservice.getBekredtelseFormGroup();
    this.beskjedForm = this.gbookingservice.getBeskjedFormGroup();
    this.aroute.params.subscribe((params) => {
      this.resourceUrl = params["refId"] || this.resourceUrl;
    });
    this.waiver_rules$ = this.service.getWaiverText().map((data) => {
      var waiverPage: any = data;
      var waiver_text = waiverPage.properties.test as Array<string>;
      this.waiver_rules = waiver_text;
      return this.waiver_rules;
    });
    this.catalog$ = this.catalogService.getCatalog();
    this.catalog$.subscribe((catalog) => {
      this.extrasForm = this.gbookingservice.getExtrasFrom(catalog);
      this.newbookingform = this.extrasForm.value;
      this.catalog = catalog;
    });
  }

  ngOnInit() {
    this.addLair();
    this.bookingFormService.getReservationForm().subscribe((form) => {
      this.reservationForm = form;
    });
  }

  setMonthSchedule(monthSchedule: Schedule) {
    this.monthSchedule = monthSchedule;
  }

  requestConfirmation() {
    this.confirmSmSCode = true;
    var details: WaiverForm = this.detailsForm.value;

    this.confirmationRequest = {
      id: Guid.empty().toString(),
      documentId: details.id,
      logEntry: { mobile: this.detailsForm.controls.mobile.value },
      occuredTime: moment().utc(),
      tenantId: this.monthSchedule.getTenantId(),
    };
    this.confirmationLog = {
      id: Guid.empty().toString(),
      documentId: details.id,
      logEntry: { mobile: this.detailsForm.controls.mobile.value, code: "" },
      occuredTime: null,
      tenantId: this.monthSchedule.getTenantId(),
    };

    setTimeout(() => {
      this.signature.requestConfirmation();
    });
  }

  signAndConfirm() {
    return !this.checked || this.confirmSmSCode;
  }
  mobileChanged() {
    if (this.newPhoneNumber()) {
      this.confirmSmSCode = false;
    }
  }
  newPhoneNumber() {
    var details: WaiverForm = this.detailsForm.value;
    if (this.confirmationRequest) {
      return (
        this.confirmationRequest &&
        this.confirmationRequest.logEntry &&
        this.confirmationRequest.logEntry.mobile &&
        this.confirmationRequest.logEntry.mobile != details.mobile
      );
    }
    return false;
  }

  getControll(name): any {
    return name;
  }
  next() {
    this.rou.navigateByUrl(`/bconfirmed/${this.resourceUrl}`);
  }
  timeSlotSelected(selected: TimeSlot) {
    this.reservation = this.bookingFormService.getReservation(
      this.monthSchedule
    );
  }
  inspect() {
    console.log(this.extrasForm.controls.drikke);
    console.log(this.extrasForm.controls.tillegg);
  }
  addLair() {
    //this.children.push(this.gfb.group(new Person('','',null)));
    this.children.push(
      this.gfb.group({
        firstname: ["", Validators.required],
        lastname: [""],
        birth: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /(^((0[1-9])|([1-2][0-9])|(3[0-1])){1}((0[1-9])|(1[0-2])){1}((19[0-9]{2})|(20[0-9]{2})){1}$)/
            ),
          ],
        ],
      })
    );
  }
  delete(index) {
    var adressList = this.children.removeAt(index);
    this.children.markAsDirty();
  }

  get tillegg(): FormArray {
    return this.extrasForm.get("tillegg") as FormArray;
  }

  get drikke(): FormArray {
    return this.extrasForm.get("drikke") as FormArray;
  }

  get children(): FormArray {
    return this.detailsForm.get("children") as FormArray;
  }

  showRules() {
    this.showWaiverRules = true;
    this.showWaiverRulesbutton = false;
    this.hildeWaiverRulesbutton = true;
  }

  hildeRules() {
    this.showWaiverRules = false;
    this.showWaiverRulesbutton = true;
    this.hildeWaiverRulesbutton = false;
  }
  checkboks(e) {
    this.showWaiverRules = false;
    this.showWaiverRulesbutton = true;
  }
  get minParticipants() {
    return Reservation.minParticipants;
  }
}
