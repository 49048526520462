export const environment = {
  version: "1.0.0-41519",
  production: false,
  tenantId: "9C0C8951-B0B1-42B3-A638-07F765E20CC7",
  dependencies: {
    waiver_api: {
      url: "https://skylandapi.bitmesh.no",
      domain: "skylandapi.bitmesh.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    products_api: {
      url: "https://skylandapi.bitmesh.no",
      domain: "skylandapi.bitmesh.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    activities_api: {
      url: "https://skylandapi.bitmesh.no",
      domain: "skylandapi.bitmesh.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    resources_api: {
      // url: "https://skylandapi.bitmesh.no",
      // domain: "skylandapi.bitmesh.no",
      url: "http://localhost",
      domain: "localhost",
    },
    waiver_rules_api: {
      url: "https://skylandapi-dev.chkit.no",
      domain: "skylandapi-dev.chkit.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    signature_log_api: {
      url: "https://skylandapi-dev.chkit.no",
      domain: "skylandapi-dev.chkit.no",
    },
    time_scheduling_api: {
      url: "https://skylandapi-dev.chkit.no",
      domain: "skylandapi-dev.chkit.no",
      // url: "http://localhost",
      // domain: "localhost",
    },
    waiver_text_api: { url: "https://skylandapi-dev.chkit.no" },
    catalog_api: { url: "https://skylandapi-dev.chkit.no" },
    entity_api: {
      url: "https://entityapi.bitme.sh",
      domain: "entityapi.bitme.sh",
    },
    file_api: { url: "https://fileapi.bitme.sh", domain: "fileapi.bitme.sh" },
    // waiver_api: {url: 'http://52.226.72.57', domain :'52.226.72.57'},
    // signature_log_api: {url: 'http://52.226.72.57', domain :'52.226.72.57'},
    // waiver_text_api:{url: ''}
  },
  config: {
    tenant: "577c80ff-449e-4fb3-897f-269d41e57e8b",
    //clientId: 'c0570187-e61a-46da-b197-1007ee995a7d',
    clientId: "300dc07f-ae46-466e-8e12-d1e68df3079e",
    redirectUri: window.location.origin,
    expireOffsetSeconds: 60,
    endpoints: {
      "https://skylandapi.bitmesh.no/api/":
        "300dc07f-ae46-466e-8e12-d1e68df3079e",
      "https://entityapi.bitme.sh/api/": "c0570187-e61a-46da-b197-1007ee995a7d",
      "https://fileapi.bitme.sh/api/": "c0570187-e61a-46da-b197-1007ee995a7d",
      "http://localhost/api/": "300dc07f-ae46-466e-8e12-d1e68df3079e",
    },
    cacheLocation: "localStorage",
  },
};
