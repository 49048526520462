import { Injectable } from "@angular/core";
import { DaySchedule } from "./day-schedule.model";
import * as moment from "moment";
import { DateSchedule } from "./date-schedule.model";
import { Reservation, CapacityTimeSlot, ReservationsAgregate } from "./timeslot.model";
import { Guid } from "../system/guid";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/reduce";
import "rxjs/add/observable/of";
import "rxjs/add/observable/forkJoin";
import "rxjs/add/observable/throw";
import { range } from "rxjs/observable/range";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GroupReservation } from "./group/GroupReservation";
import { Schedule } from "./schedule.model";
import { TimeSlotBookings } from "./time-slot-bookings.model";
import { CalendarTool } from "../system/calendartool";
import { map } from "rxjs/internal/operators/map";
import { mergeMap } from "rxjs/operators";
import { from } from "rxjs/internal/observable/from";
import { observable } from "rxjs";
@Injectable({ providedIn: "root" })
export class TimeScheduleService {
  private readonly base_schedule_url =
    environment.dependencies.time_scheduling_api.url;
  private readonly reservations_url =
    this.base_schedule_url + "/api/reservations";
  private readonly dayschedule_url =
    this.base_schedule_url + "/api/dayschedules";

  constructor(private http: HttpClient) {}
  // TODO Make select list of all resources.
  // show capacity results related to selected resource
  public getSchedule(
    type: string = "",
    referenceId: string,
    date: moment.Moment = moment()
  ): Observable<Schedule> {
    var startOfDay = CalendarTool.justDate(date);
    return Observable.forkJoin([
      this.getDaySchedules(type, referenceId),
      this.getAllReservations(referenceId, startOfDay),
    ]).pipe(
      map((data) => {
        const referenceReservations = [].concat(...data[1].map(x=>x.reservations.filter(r=>r.referenceId === referenceId)));
        return new Schedule(startOfDay, data[0], referenceId, referenceReservations);
      })
    );
  }

  public getDaySchedules(
    type: string= "",
    referenceId: string 
  ): Observable<Array<DaySchedule>> {
    var params = {
      params: {
        referenceId: referenceId,
        scheduleType: type,
      },
    };
    // return this.http.get(`${this.dayschedule_url}?referenceId=${referenceId}&scheduleType=${type}`).map((data: Array<any>)=>{
    return this.http.get(this.dayschedule_url, params).pipe(
      map((data: Array<any>) => {
        return data.map((day) => {
          return DaySchedule.deSerialize(day);
        });
      })
    );
  }
  public getDaySchedule(id: string): Observable<DaySchedule> {
    return this.http.get(`${this.dayschedule_url}/${id}`).pipe(
      map((day: any) => {
        return DaySchedule.deSerialize(day);
      })
    );
  }
  putDaySchedule(daySchedule: DaySchedule) {
    return this.http.put(
      `${this.dayschedule_url}/${daySchedule.id}`,
      daySchedule
    );
  }
  deleteDaySchedule(daySchedule: DaySchedule) {
    return this.http.delete(`${this.dayschedule_url}/${daySchedule.id}`);
  }
  // TODO add logick to Schedule object
  public mapToWeekSchedule(
    ob: Observable<Array<DaySchedule>>
  ): Observable<Map<string, DaySchedule>> {
    return ob.pipe(
      map((daySchedules: Array<DaySchedule>) => {
        return daySchedules.reduce(
          (map: Map<string, DaySchedule>, daySchedule: DaySchedule) => {
            return daySchedule.weekDays.reduce(
              (map: Map<string, DaySchedule>, daynr: number) => {
                map[daynr] = daySchedule;
                return map;
              },
              map
            );
          },
          new Map<string, DaySchedule>()
        );
      })
    );
  }

  public getMonthCapacity(
    date: moment.Moment,
    type: string = "",
    referenceId: string
  ): Observable<Map<string, Array<CapacityTimeSlot>>> {
    return this.getSchedule(type, referenceId, date).map((schedule) => {
      return schedule.countCapacity();
    });
  }
  private mapReservation(x: any): Reservation {
    return Reservation.mapReservation(x);
  }
  private mapReservations(m: Array<any>): Array<ReservationsAgregate> {
    const canceledReservations = {};
    var reservationMap = m
      .map((x) =>{
        var reservations = Reservation.mapReservation(x);
        return reservations;
      })
      .reduce((acc: Map<string, ReservationsAgregate>, r: Reservation) => {
        var reservation = acc.get(r.reservationId);
        if(!reservation){
          acc.set(r.reservationId, new ReservationsAgregate(r));
        }
        else if(reservation) {
          reservation.addReservation(r);
          return acc;
        }
        return acc;
      }, new Map<string, ReservationsAgregate>());
    return Array.from([...reservationMap.values()].filter(x=>!x.isCanceled));
  }

  public getAllReservations(
    referenceId: string,
    date: moment.Moment = moment()
  ): Observable<Array<ReservationsAgregate>> {
    return this.http
      .get(
        `${
          this.reservations_url
        }/${referenceId}/private?date=${date.toISOString()}`
      )
      .pipe(map((a: Array<any>) =>{
        return this.mapReservations(a);
      }));
  }
  public getTimeslotBookings(
    starOfTimeslot: moment.Moment,
    referenceId: string
  ): Observable<TimeSlotBookings> {
    return this.http
      .get(
        `${
          this.reservations_url
        }/timeslot/${starOfTimeslot.toISOString()}/${referenceId}`
      )
      .pipe(map((a: Array<any>) => {
        return this.mapReservations(a);
      }))
      .pipe(map((reservations) => {
        return new TimeSlotBookings(reservations)
      }));
  }
  public makeReservation(
    reservation: Reservation,
    monthSchedule: Schedule
  ): Observable<any> {
    return this.getReservations(reservation).flatMap((reservations) => {
      monthSchedule.setReservations(
        reservation.start(),
        reservations as Array<Reservation>
      );
      if (monthSchedule.addReservation(reservation)) {
        return this.postReservation(reservation).pipe(
          map((x: any) => this.mapReservation(x))
        );
      }
      throw new HttpErrorResponse({
        error: { msg: "fully booked", full: true },
        status: 400,
      });
      // Error(JSON.stringify({ status: 400, msg: 'fully booked', full: true }));
    });
  }

  getReservations(reservation: Reservation): Observable<Array<Reservation>> {
    var date = reservation.start();
    var referenceId = reservation.referenceId;
    return this.http
      .get(`${this.reservations_url}/${referenceId}`, {
        params: { date: date.format("DD.MM.YYYY") },
      })
      .pipe(map((x: Array<any>) => this.mapReservations(x).map(x=>x.reservation)));
  }
  searchReservations(search: string): Observable<Array<ReservationsAgregate>> {
    return this.http
      .get(`${this.reservations_url}/search`, { params: { search: search } })
      .pipe(map((x: Array<any>) => this.mapReservations(x)));
  }

  postReservation(reservation: Reservation): Observable<any> {
    return this.http.post(this.reservations_url, reservation);
  }
  postMultipleReservation(reservations: Reservation[]): Observable<any> {
    return this.http.post(`${this.reservations_url}/multiple`, reservations);
  }

  postGroupReservation(groupReservation: any): Observable<any> {
    return this.http.post(`${this.reservations_url}/groups`, groupReservation);
  }
  
  rescheduleGroupReservation(oldGroupReservation: GroupReservation, newGroupReservation: GroupReservation): Observable<any> {
    const reservations = [oldGroupReservation.reservation,...oldGroupReservation.reservations.filter(x=>x.id !== oldGroupReservation.reservation.id)];
    var cancelations = this.mapToCancelations(reservations);
    return this.http.put(
      `${this.reservations_url}/groups/${newGroupReservation.id}/reschedule`,
      {newReservation:newGroupReservation,cancelations:cancelations}
    );
  }

  putGroupReservation(groupReservation: GroupReservation): Observable<any> {
    return this.http.put(
      `${this.reservations_url}/groups/${groupReservation.id}`,
      groupReservation
    );
  }

  public makeGroupeReservation(groupReservation: any, monthSchedule: Schedule) {
    var reservation = groupReservation.reservation as Reservation;
    return this.getReservations(reservation).flatMap((reservations) => {
      monthSchedule.setReservations(
        reservation.start(),
        reservations as Array<Reservation>
      );
      if (monthSchedule.addReservation(reservation)) {
        return this.postGroupReservation(groupReservation).pipe(
          map((x: any) => this.mapReservation(x))
        );
      }
      throw { status: 400, msg: "fully booked" };
    });
  }
  getGroupReservationsPast(
    date: moment.Moment,
    referenceId: string
  ): Observable<Array<GroupReservation>> {
    // return this.http.get(`${this.reservations_url}/groups?referenceId=${referenceId}`,{params:params})
    return this.http
      .get(`${this.reservations_url}/groups/past`, {
        params: {
          date: date.toISOString(),
          referenceId: referenceId,
        },
      })
      .map((r: Array<any>) => {
        return r.map((i) => {
          var res = i as GroupReservation;
          if (res.reservation) {
            res.reservation = this.mapReservation(res.reservation);
          }
          return res;
        });
      });
  }

  getPastRegularReservations(
    date: moment.Moment,
    referenceId: string
  ): Observable<Array<Reservation>> {
    return this.http.get<Array<Reservation>>(
      `${this.reservations_url}/regular/past`,
      {
        params: {
          date: date.toISOString(),
          referenceId: referenceId,
        },
      }
    );
  }

  getGroupReservations(
    date: moment.Moment,
    referenceId: string,
    dateTo?: moment.Moment,
  ): Observable<Array<GroupReservation>> {
    // return this.http.get(`${this.reservations_url}/groups?referenceId=${referenceId}`,{params:params})
    return this.http
      .get(`${this.reservations_url}/groups`, {
        params: {
          date: date.toISOString(),
          ...(dateTo ? {dateTo: dateTo.toISOString()} : {}),
          referenceId: referenceId,
        },
      })
      .pipe(
        map((r: Array<any>) => {
          return r.map((i) => {
            var res = i as GroupReservation;
            if (res.reservation) {
              res.reservation = this.mapReservation(res.reservation);
            }
            return res;
          });
        })
      );
  }
  getGroupReservationById(id: string): Observable<GroupReservation> {
    return this.http.get(`${this.reservations_url}/groups/${id}`).pipe(
      map((data) => {
        var res = data as GroupReservation;
        res.reservation = Reservation.mapReservation(res.reservation);
        res.reservations = res.reservations?.map(Reservation.mapReservation);

        return res;
      })
    );
  }
  cancelGroupReservation(group:GroupReservation):Observable<any>{
    const reservations = [group.reservation,...group.reservations.filter(x=>x.id !== group.reservation.id)];
    return this.cancelMany(reservations);    
  }
  mapToCancelations(reservations: Reservation[]):Reservation[]{
    const openReservations = reservations.sort((a,b)=>a.occuredTime.diff(b.occuredTime));
    const referenceReservations = openReservations.reduce((acc,reservation)=>{
      if(!acc[reservation.referenceId]){
        acc[reservation.referenceId] = [];
      }
      acc[reservation.referenceId].push(reservation);
      return acc;
    },{});
    const filtered = Object.keys(referenceReservations).map(x=>referenceReservations[x]).filter(x=>{
      const sum = x.reduce((a,c)=>a+c.count,0);
      return sum > 0
    }).map(x=>{
      var notCanceled = [];
      var sum = 0;
      x.forEach((x,i)=>{
        notCanceled.push(x);
        sum += x.count;
        if(sum <= 0){
          notCanceled = notCanceled.slice(i);
        }
      })
      return notCanceled;
    });
    var now = moment().utc();
    const cancelationList = [].concat(...filtered).map((r)=>this.mapToCancelation(r,now));
    if(cancelationList.length <= 0) return null;
    return cancelationList;
  }

  cancelMany(reservations: Reservation[]): Observable<any> {
    var cancelationList = this.mapToCancelations(reservations);
    if(!cancelationList) return from([{msg:"All canceled"}]);
    return this.postMultipleReservation(cancelationList);
  }

  cancel(reservation: Reservation): Observable<any> {
    var cancelation = this.mapToCancelation(reservation);
    return this.postReservation(cancelation);
  }

  private mapToCancelation(reservation: Reservation, now: moment.Moment = moment().utc()) {
    return Reservation.mapReservation({ ...reservation, count: -reservation.count, id: Guid.newGuid().toString(), typeName: reservation.type,occuredTime: now });
  }
  private mapToCheckin(reservation: Reservation) {
    return Reservation.mapReservation({ ...reservation, count: 0, id: Guid.newGuid().toString(), typeName: reservation.type });
  }


  checkin(reservation: ReservationsAgregate): Observable<any> {
    if(reservation.checkedIn) return from([{msg:"All ready checked in"}]);
    const checkin = this.mapToCheckin(reservation.reservation);
    return this.postReservation(checkin);
  }  
}
