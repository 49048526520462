
import { Person } from './Person.model';
import * as moment from 'moment';

export class  WaiverForm{
   
    public id : string;
    public contactPerson: Person;
    public children: Array<Person>;
    public email: string;
    public mobile: string;
    public signingdate: moment.Moment;
    public advertising: boolean;
    public referenceId:string;
    public tenantId: string;

    constructor(){}
}