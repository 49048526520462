import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BookingFormService } from "../booking-form.service";
import { FormGroup } from "@angular/forms";
import { TimeSlot } from "../timeslot.model";
import { BookableResource } from "../bookable-resource";
import { BookableResourceService } from "../bookable-resource.service";
import { Observable } from "rxjs/Observable";
@Component({
  selector: "app-vr-booking",
  templateUrl: "./vr-booking.component.html",
  styleUrls: ["./vr-booking.component.scss"],
})
export class VrBookingComponent implements OnInit {
  reservationForm: FormGroup;
  resourceUrl: string = "krsvag";
  public resource$: Observable<BookableResource>;
  public resource: BookableResource;
  constructor(
    private router: Router,
    private bookingFormService: BookingFormService,
    private bookingResourceService: BookableResourceService,
    private cdRef: ChangeDetectorRef,
    private aroute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.bookingFormService.getReservationForm().subscribe((form) => {
      this.reservationForm = form;
    });
    this.aroute.params.subscribe((params) => {
      this.resourceUrl = params["refId"] || this.resourceUrl;
      this.resource$ = this.bookingResourceService.getResource(
        this.resourceUrl
      );
      this.resource$.subscribe((r) => {
        this.resource = r;
      });
    });
  }

  timeSlotSelected(event: TimeSlot) {
    //this.next();
    this.cdRef.detectChanges();
  }

  next() {
    this.router.navigateByUrl("/book/confirm/" + this.resourceUrl);
  }
  ngAfterViewInit() {
    //this.cdRef.detectChanges();
  }
}
