import { MatMenu } from '@angular/material/menu';

export class MenuItem {
    text: string;
    routerLink?: string;
    icon?: string;
    tooltipPosition?: string;
    routerLinkActiveOptions?: any;
    hidden?: boolean;
    disabled?: boolean;
    position?: 'top' | 'bottom' = 'top';
    hasSubMenu?: boolean;
    menuTrigger?: MatMenu;
}
