

import * as moment from 'moment';
import { Person } from '../waiver/Person.model';
import { WaiverForm } from '../waiver/waiverform.model';
import { Booking } from './Booking.model';
import { Allergi } from './booking/Allergi.model';
import { Bursdagspakke } from './Bursdagspakke.model';

export class  GroupbookingForm {
   
    public newWaiverForm : WaiverForm;
    public bookingForm : Booking;
    public bursdagspakkeForm: Bursdagspakke;
    public allergipakkeForm: Allergi;
    
    constructor(){}
}


