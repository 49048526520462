import moment from "moment";
import { DayScheduleTypes } from "../day-schedule.model";
import { Guid } from "../../system/guid";

export class TimeSlot {
  constructor(
    public startTime: moment.Moment,
    public duration: number,
    public readonly referenceId: string,
    public id: string = Guid.newGuid().toString()
  ) {
    this.startTime = this.startTime
      .clone()
      .set({ seconds: 0, milliseconds: 0 });
  }
  public start(): moment.Moment {
    return this.startTime.clone();
  }

  public end(): moment.Moment {
    return this.startTime.clone().add(this.duration, "minutes");
  }

  public splitOn(duration: number): TimeSlot[] {
    const result: TimeSlot[] = [];
    let t = new TimeSlot(this.start(), duration, this.referenceId);
    while(t.isInInterval(this)) {
      result.push(t);
      t = new TimeSlot(t.start().add(duration, 'minutes'), duration, this.referenceId);
    }
    return result;
  }

  // New interval check that is used in group booking logic
  isInInterval(timeslot: TimeSlot): boolean {
    return this.start().isSameOrAfter(timeslot.start()) &&
      this.end().isSameOrBefore(timeslot.end());
  }

  isIn(timeslot: TimeSlot): boolean {
    return (
      this.isInTimeslot(timeslot, this) || this.isInTimeslot(this, timeslot)
    );
  }

  private isInTimeslot(timeslot1: TimeSlot, timeslot2: TimeSlot): boolean {
    var t =
      timeslot1.start().isSameOrAfter(timeslot2.start()) &&
      timeslot1.start().isBefore(timeslot2.end());
    return t;
  }
}

export class Reservation extends TimeSlot {
  public readonly type: string = "Reservation";
  public referenceNumber: string = '';
  public reservationId: string;
  public checkedIn: boolean = false;
  public static minParticipants = 5;
  public readonly typeName:string= "Reservation";
  public get cssClass(): string {
    return this.checkedIn ? "booking checked_in" : "booking";
  }
  constructor(
    startTime: moment.Moment,
    duration: number,
    public count: number,
    public mobile: string,
    referenceId: string,
    public tenantId: string,
    public unitPrice: number = 0,
    public dayScheduleType: DayScheduleTypes = DayScheduleTypes.Regular,
    public occuredTime: moment.Moment = moment(),
    id: string = Guid.newGuid().toString()
  ) {
    super(startTime, duration, referenceId, id);
    this.reservationId = this.id;
  }
  public get durationHours(): number {
    return this.duration / 60;
  }

  public getTotalPrice(): number {
    return this.count > Reservation.minParticipants
      ? this.count * this.unitPrice
      : Reservation.minParticipants * this.unitPrice;
  }
  // // TODO: Find what this is used for
  // public isRegular(): boolean {
  //   return this.dayScheduleType === DayScheduleTypes.Regular;
  // }

  public static mapReservation(x: any): Reservation {
    if(x.type === "ActivityReserved") return ActivityReserved.mapReservation(x);
    var m = new Reservation(
      moment(x.startTime).local(),
      x.duration,
      x.count,
      x.mobile,
      x.referenceId,
      x.tenantId,
      x.unitPrice,
      x.dayScheduleType,
      x.occuredTime,
      x.id
    );
    m.referenceNumber = x.referenceNumber;
    m.reservationId = x.reservationId;
    return m;
  }
}
export class ActivityReserved extends Reservation {
  public readonly type: string = "ActivityReserved";
  public readonly typeName:string= "ActivityReserved";
  constructor(
    startTime: moment.Moment,
    duration: number,
    count: number,
    mobile: string,
    referenceId: string,
    tenantId: string,
    unitPrice: number = 0,
    dayScheduleType: DayScheduleTypes = DayScheduleTypes.Regular,
    occuredTime: moment.Moment = moment(),
    id: string = Guid.newGuid().toString()
  ) {
    super(startTime, duration, count,mobile, referenceId, tenantId, unitPrice, dayScheduleType,occuredTime,id);
  }

  public static mapReservation(x: any): Reservation {
    var m = new ActivityReserved(
      moment(x.startTime).local(),
      x.duration,
      x.count,
      x.mobile,
      x.referenceId,
      x.tenantId,
      x.unitPrice,
      x.dayScheduleType,
      x.occuredTime,
      x.id
    );
    m.referenceNumber = x.referenceNumber;
    m.reservationId = x.reservationId;
    return m;
  }
}
export class ReservationInfo {
  private events: Array<Reservation> = [];
  private checkedin: boolean = false;
  constructor(public reservation: Reservation) {
    this.events.push(reservation);
  }
  public addReservationInfo(info: Reservation) {
    if (info.count === 0) {
      this.checkedin = true;
    }
    this.events.push(info);
  }
  public isCheckedin(): boolean {
    return this.checkedin;
  }
}

export class CapacityTimeSlot extends TimeSlot {
  public referenceNumber: string = '';
  constructor(
    startTime: moment.Moment,
    duration: number,
    public count: number,
    public totalCapacity: number,
    referenceId: string,
    id: string = Guid.newGuid().toString()
  ) {
    super(startTime, duration, referenceId, id);
  }
  public get durationHours(): number {
    return this.duration / 60;
  }

  public getTotalPrice(): number {
    return 0;
  }
}
