import {
  Component,
  OnInit,
  EventEmitter,
  Inject,
  NgZone,
  ApplicationRef,
  ChangeDetectorRef
} from "@angular/core";

import { Observable } from "rxjs/Observable";

import * as moment from "moment";
import { CalendarTool } from "../../system/calendartool";
import { GroupReservation } from "../../time-scheduling/group/GroupReservation";
import { TimeScheduleService } from "../../time-scheduling/time-schedule.service";
import { range } from "rxjs/observable/range";
import { BehaviorSubject } from "rxjs";
import { BookingFormService } from "../../time-scheduling/booking-form.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BookableResource } from "../../time-scheduling/bookable-resource";
import { BookableResourceService } from "../../time-scheduling/bookable-resource.service";
import { ActivatedRoute } from "@angular/router";
import { Chanels } from "../../chanels";
import { ServiceBusService } from "../../system/service-bus.service";

@Component({
  selector: "app-birthdaybooking-past",
  templateUrl: "./birthdaybooking-past.component.html",
  styleUrls: ["./birthdaybooking-past.component.scss"]
})
export class BirthdaybookingPastComponent implements OnInit {
  public currentDay: moment.Moment = moment()
    .startOf("month")
    .add(2, "months");
  public offset: number = 0;
  public groupReservation$: Observable<Array<GroupReservation>>;
  public past$: Observable<Array<GroupReservation>>;
  public now$: Observable<Array<GroupReservation>>;
  public pastBookingList: Array<GroupReservation>;
  public resource$: Observable<BookableResource>;
  public resource: BookableResource;
  public returningCustomer: Map<string, GroupReservation> = new Map<
    string,
    GroupReservation
  >();
  public pastBookings: Map<string, GroupReservation> = new Map<
    string,
    GroupReservation
  >();
  constructor(
    private timeScheduleService: TimeScheduleService,
    private aroute: ActivatedRoute,
    private bus: ServiceBusService,
    public dialog: MatDialog,
    private zone: NgZone,
    private ref: ChangeDetectorRef
  ) {}
  private firstDay: moment.Moment = moment().weekday(0);
  ngOnInit() {
    this.resource$ = this.bus.chanelObservable(Chanels.RESOURCE);
    this.resource$.subscribe(r => {
      if (!r) return;
      this.resource = r;
      this.getGroupReservations();
    });
  }

  private getGroupReservations() {
    this.groupReservation$ = this.timeScheduleService.getGroupReservationsPast(
      this.currentDay,
      this.resource.id
    );
    var currentYear = this.currentDay.year();
    this.past$ = this.groupReservation$.map(x =>
      x.filter(y => moment(y.reservationDate).year() < currentYear)
    );
    this.now$ = this.groupReservation$.map(x =>
      x.filter(y => moment(y.reservationDate).year() >= currentYear)
    );
    const mapTo = (map: Map<string, GroupReservation>) => {
      return (reservations: Array<GroupReservation>) => {
        reservations.reduce((acc, curr) => {
          var keys = this.getBookingKey(curr);
          keys.forEach(key => acc.set(key, curr));
          return acc;
        }, map);
      };
    };
    this.returningCustomer = new Map<string, GroupReservation>();
    this.pastBookings = new Map<string, GroupReservation>();
    this.now$.subscribe(mapTo(this.returningCustomer));
    this.past$.subscribe(mapTo(this.pastBookings));
    this.past$.subscribe(data => {
      this.pastBookingList = data;
    });
  }

  private getBookingKey(reservation: GroupReservation): Array<string> {
    return [reservation.details.email, reservation.details.mobile];
  }

  hasReBooked(reservation: GroupReservation): boolean {
    var keys = this.getBookingKey(reservation);
    return keys.filter(key => this.returningCustomer.has(key)).length > 0;
  }
  hasBooked(reservation: GroupReservation): boolean {
    var keys = this.getBookingKey(reservation);
    return keys.filter(key => this.pastBookings.has(key)).length > 0;
  }
  getCurrentMonth(): string {
    return this.currentDay.format("MMM");
  }
  onClickNavigate_before() {
    // this.zone.run(() => {z
    this.offset--;
    this.currentDay = this.currentDay.add(-1, "month");
    this.ref.markForCheck();
    this.getGroupReservations();
    // });
  }

  onClickNavigate_next() {
    this.offset++;
    this.currentDay = this.currentDay.add(1, "month");
    this.getGroupReservations();
  }
  export() {
    var csvContent =
      "Dato;Fornavn;Etternavn;E-post;mobil;barn\r\n";
    csvContent += this.pastBookingList
      .filter(v => !this.hasReBooked(v) && v.reservationId)
      .map(v => {
        var csv = `"${v.reservationDate}";"${v.details.contactPerson.firstname}";"${v.details.contactPerson.lastname}";"${v.details.email}";"${v.details.mobile}"`;
        csv +=
          ";" +
          v.details.children
            .map(x => `"${x.firstname}";"${x.lastname}";"${x.birth}"`)
            .join(";");
        return csv;
      })
      .join("\r\n");
    this.saveFile(csvContent,"bursdags-export.csv");
    // var encodedUri = encodeURI(csvContent);
    // var link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", );
    // document.body.appendChild(link); // Required for FF

    // link.click(); // This will download the data file named
    // document.body.removeChild(link);
  }

  saveFile(csvFile:string, filename: string){
    var universalBOM = "\uFEFF";
    var blob = new Blob([universalBOM + csvFile], { type: 'text/csv;charset=utf-8;' });
    if ((navigator as any).msSaveBlob) { // IE 10+
        (navigator as any).msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }
}
