import { Component, OnInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-gbooking',
  templateUrl: './gbooking.component.html',
  styleUrls: ['./gbooking.component.scss']
})
export class GbookingComponent implements OnInit {
  
  constructor() { 
   
  }
  public weekdays:Array<object> = [
    { day: 'Mandag',dato:'30 mai'},
    { day: 'Tirsdag',dato:'31 mai'},
    { day: 'Onsdag',dato:'01 juni'},
    {day: 'Torsdag ',dato:'02 juni'},
    {day: 'Fredag ',dato:'03 juni'},
    {day: 'Lørdag ',dato:'04 juni'},
    {day: 'Søndag ', dato:'05 juni'},
];
public Reservation:Array<object> = [
  { Navn: '5'},
  { antall: '5'},
  { alder: '10'},
  { hoppetid: '2 time'},
  { foods: 'Hjemmebakt pizza'},
  {dessert: 'Gullrotkake '},
  {drikke: 'Slush '},
  {tilegg: 'Filming &Fotografering '},
  {Allergi: 'laktosefri '},
];

  ngOnInit() {
  }

  
}
