import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { BookableResourceService } from '../time-scheduling/bookable-resource.service';
import { Observable } from 'rxjs';
import { BookableResource } from '../time-scheduling/bookable-resource';
import { ServiceBusService } from '../system/service-bus.service';
import { AdalService } from 'adal-angular4';
import { Chanels } from '../chanels';
import { BUser } from '../system/buser';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  //@ViewChild("mainMenu") mainMenu: MatMenu;
  public resource: BookableResource;
  bookableResources$: Observable<Array<BookableResource>>;
  public user: BUser;
  constructor(private bookingResourceService: BookableResourceService,
    private adalService: AdalService,
    private bus:ServiceBusService) { }

  ngOnInit() {

    this.bus.subscribe(Chanels.RESOURCE,(r)=>{
      this.resource = r as BookableResource;
    });
    this.bus.subscribe(Chanels.USER_CHANEL,(user)=>{
      if(!user) return;
      this.user = user;
      this.bookableResources$ = this.bookingResourceService.getResources()
        .map(x=>{
          return x.filter((q:BookableResource)=>user.isAdmin() || user.hasSiteAccess(q.id));
      });
    });
  }
  logout(){
    this.adalService.logOut();
  }
}
